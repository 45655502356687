<template>
  <div class="merch-box">
    <div class="title">
      供应商信息
      <div
        class="toQua"
        @click="toMessage(businessInfoNew)"
        v-if="allJurisdiction.merchantCenterList148"
      >
        查看资质
      </div>
    </div>
    <div class="center-box">
      <div
        v-for="(item, index) in businessInfo"
        :key="index"
        :class="item.key == 'typeName' ? 'all' : 'half'"
      >
        <div v-if="item.key == 'typeName' && businessInfoNew">
          <span class="lable">{{ item.name }}</span>
          <span
            v-for="(v, i) in businessInfoNew[item.key]"
            :key="i"
            class="list"
            >{{ v }}</span
          >
        </div>
        <div class="InfoNew" v-if="item.key != 'typeName' && businessInfoNew">
          <div v-if="item.name != '企业类型'">
            <span class="lable">{{ item.name }}</span>
            {{ businessInfoNew[item.key] }}
          </div>
          <div v-else class="business-box">
            <span class="lable">{{ item.name }}</span>
            <div>
              <span class="business">{{ businessInfoNew[item.key] }}</span>   
              <span v-for="(v,i) in businessInfoNew.detailsType" :key="i">{{ v }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>查看商家资质></div> -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  props: {
    businessInfo: {
      type: Array,
      default: () => [],
    }, //供应商配置信息
    businessInfoNew: {
      type: Object,
      default: () => {},
    }, //供应商基本信息
    merchants: {
      type: Object,
      default: () => {},
    }, //供应商基本信息
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  created() {
  },
  methods: {
    toMessage(row) {
      this.$router.push({
        path: "/merchantMessage",
        query: { id: row.business_id, type: 1 },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
    .toQua {
      color: #00bfbf;
      font-size: 13px;
      font-weight: normal;
      cursor: pointer;
      border: #00bfbf 1px solid;
      padding: 2px 5px;
      margin-left: 5px;
      border-radius: 4px;
    }
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    position: relative;
    padding: 0 20px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    // border: 1px solid #797979;
    flex-wrap: wrap;
    line-height: 28px;
    font-size: 14px;
    color: #333;
    .lable {
      width: 80px;
      text-align: end;
      display: inline-block;
      color: #666;
      margin-right: 15px;
      border: none !important;
    }
    .all {
      width: 100%;
      .list {
        width: 100%;
        text-align: center;
        display: inline;
        background-color: #06b7ae;
        color: #fff;
        padding: 0 5px;
        border-radius: 4px;
        margin-right: 5px;
      }
    }
    .half {
      width: 50%;
    }
  }
}
.InfoNew {
  // margin-top: 28px;
  padding: 10px 0;
}

.business-box{
  display: flex;
  div{
    
  }
  span{
    display: inline-block;
    margin-right: 5px;
    text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dfdfdf;
  line-height: 16px;
  border-radius: 2px 2px 2px 2px;
  padding: 2px 5px;
  color: #666;
  box-sizing: border-box;
  }
  .business {
  background: #06b7ae;
  color: #ffffff;
  border: none;
}
}
</style>
