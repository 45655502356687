<template>
  <div>
    <div class="message-box">
      <div>
        <div class="title">商品分佣</div>
        <div class="message-box-box">
          <div class="message-box-left">
            <el-descriptions :column="1">
              <el-descriptions-item
                :label="item.name"
                v-for="(item, index) in basicInfo"
                :key="index + ''"
              >
                {{ basicinfoData[item.key] || "/" }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>

      <div>
        <div class="title">商品销售区域</div>
        <div class="message-box-box">
          <div class="message-box-left">
            <el-descriptions :column="1">
              <el-descriptions-item
                :label="item.name"
                v-for="(item, index) in basicInfo"
                :key="index + ''"
              >
                {{ basicinfoData[item.key] || "/" }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //数据商品基本
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  .title {
    width: 120px;
    margin-top: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00bfbf;
    color: #fff;
    border-radius: 4px;
  }
  .message-box-box {
    margin-top: 10px;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    // width: 48%;
    border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    // width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      width: 100%;
      border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
</style>
