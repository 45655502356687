<template>
  <div>
    <!-- {{ qualifyData }} -->
    <div class="merch-box" v-for="(item, index) in qualify" :key="index">
      <div class="title">{{ item.name }}</div>
      <div class="center-box">
        <div class="img-box" v-for="(elem, inde) in qualifyData" :key="inde">
          <div class="img-title exceed" :title="elem.name">{{ elem.name }}</div>
          <div>
            <template v-for="v in elem.images">
              <el-image
                :key="v"
                style="width: 100px; height: 100px"
                :src="v"
                :preview-src-list="elem.images"
              >
              </el-image>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qualify: {
      type: Array,
      default: () => {
        return [];
      },
    }, //资质配置
    qualifyData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
     width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
   padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(6, 16.6%);
    font-size: 12px;
    .img-box {
      // margin-right: 10px;
      width: 100%;
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .img-title {
        width: 130px;
        text-align: center;
        margin-bottom: 10px;
        white-space:nowrap;
        overflow:hidden; 
        text-overflow:ellipsis;
      }
    }
  }
}
</style>
