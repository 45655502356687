<template>
  <div class="merch-box">
    <div class="title">
      价格库存信息<span class="hint" v-if="$route.query.steured == 'false'">
        （*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）
      </span>
    </div>
    <div class="center-box">
      <el-descriptions :column="5">
        <el-descriptions-item
          :label="item.name"
          v-for="(item, index) in priceAndInventory"
          :key="index"
        >
          <div :class="item.key == 'sellingPrice' ? 'corf00' : 'cor333'">
            <span
              v-if="
                item.key == 'sellingPrice' ||
                item.key == 'marketPrice' ||
                item.key == 'settlementPrice' ||
                item.key == 'minimumSellingPrice'
              "
              >{{ priceData[item.key] ? "￥" : "" }}</span
            >{{ priceData[item.key] || "/" }}
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priceAndInventory: {
      type: Array,
      default: () => {
        return [];
      },
    }, //价格库存
    priceData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //价格数据
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
}
.corf00 {
  color: #f00;
  // font-weight: bold;
}
.cor333 {
  color: #333;
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}
::v-deep .el-descriptions-item__label {
  width: 80px !important;
  color: #666;
  margin-right: 44px !important;
  font-size: 14px !important;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
</style>
