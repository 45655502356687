<template>
  <div class="cloudGoodMessage MERCH">
    <div class="merch-card">
      <div class="message-box scorr-roll">
        <!-- 供应商 -->
        <div>
          <MerchantGood
            :businessInfo="goodsMessage.businessInfo"
            :businessInfoNew="AuditbusinessData.merchants"
            :merchants="AuditbusinessData.merchants"
          />
        </div>
        <!-- 基本信息 -->
        <div>
          <BasicMessageGood
            :basicInfo="goodsMessage.basicInfo"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basic_info"
            :imgDate="AuditbusinessData.commodity_images"
            :manuData="AuditbusinessData.basic_info"
            :labData="AuditbusinessData.basic_info"
          />
        </div>
        <!-- 价格/库存 -->
        <div>
          <PriceGood
            :priceAndInventory="goodsMessage.priceAndInventory"
            :priceData="AuditbusinessData.basic_info"
          />
        </div>
        <!-- 分销 -->
        <div>
          <Distributors
            :priceAndInventory="goodsMessage.distributors"
            :priceData="AuditbusinessData.basic_info"
          />
        </div>
        <!-- 销售设置 -->
        <div>
          <SellSetGood
            :saleSetting="goodsMessage.saleSetting"
            :saleData="AuditbusinessData.basic_info"
          />
        </div>
        <!-- 产品资质 -->
        <div>
          <QualificationGood
            :qualify="goodsMessage.qualify"
            :qualifyData="AuditbusinessData.product_qualification"
          />
        </div>
        <!-- 商品说明书 -->
        <div v-if="AuditbusinessData.commodity_description">
          <ExplainGood
            v-if="AuditbusinessData.commodity_description.CONTENT_TYPE == 1"
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.commodity_description.CONTENT"
          />
          <div
            v-if="AuditbusinessData.commodity_description.CONTENT_TYPE == 2"
            class="update"
          >
            <div class="title">商品说明书</div>
            <div v-html="AuditbusinessData.commodity_description.CONTENT"></div>
          </div>
        </div>
      </div>

      <!-- <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
      </div> -->
      <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
        <div class="pass" @click="toSave" v-if="$route.query.type == 3">
          保存
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import BasicMessageGood from "./goodCloud/goodCloudBasic.vue"; //基本信息
import ExplainGood from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "./goodCloud/quaGoodCloud.vue"; //产品资质
import Distributors from "@/components/ordinaryGoods/distributors.vue"; //价格/库存

import GoodFy from "@/components/ordinaryGoods/goodFy.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      activeName: 0,
      // 引入详情json
      goodsMessage: require("@/assets/json/cloudGood.json"),
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
      },
      isRefuse: false, //孔氏拒绝弹框
      ispass: false, //通过拒绝类型
      AuditbusinessData: {},
    };
  },
  components: {
    MerchantGood, //供应商
    BasicMessageGood, //基本信息
    ExplainGood, //商品说明书
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
    GoodFy, //商品分佣
    Distributors,
  },

  created() {
    // if (this.$route.query.id) {
    this.getMessage();
    // }
  },
  methods: {
    ...mapActions(["postgoodsAuditaudit", "getgoodsInfodetail"]),
    async getMessage() {
      let data = await this.getgoodsInfodetail({ id: this.$route.query.id });
      this.AuditbusinessData = data.data;
      this.AuditbusinessData.basic_info.name = data.data.appointBusiness.name;
    },
    //  返回
    toBack() {
      if (this.$route.query.push == 1) {
        this.$router.push({ path: "/goodsharing" });
      } else {
        this.$router.push({ path: "/cloudGood" });
      }
    },
    // 拒绝弹框
    refuseMessage() {
      this.isRefuse = true;
    },
    // 通过弹框
    passMessage() {
      this.ispass = true;
    },
    // 通过
    async nextPass() {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
      };
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.ispass = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 供应商审核
    goodAudit() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
      .time-wire {
        width: 100%;
        height: 100px;
        background: rebeccapurple;
      }
    }
  }
  .btn-box {
    cursor: pointer;
    margin-top: 1%;
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      width: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
    }
    .pass {
      margin-left: 15px;
      background: #06b7ae;
      color: #ffffff;
    }
    .refuse {
      background: #f2f2f2;
      color: #999999;
    }
  }
}
</style>
