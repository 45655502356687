<template>
  <div class="merch-box">
    <div class="title">
      销售设置<span class="hint" v-if="$route.query.steured=='false'"
        >
        （*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）
        </span
      >
    </div>
    <div class="center-box">
      <el-descriptions :column="1">
        <el-descriptions-item
          :label="item.name"
          v-for="(item, index) in saleSetting"
          :key="index"
        >
          <template v-if="item.key == 'supplyObject'">
            <span v-for="(v1, i1) in saleData[item.key]" :key="i1">
              <span v-for="(v, i) in userType" :key="i">
                <template v-if="v1 == v.id">
                  {{ v.type_name }}
                </template>
              </span>
            </span>
          </template>
          <template v-if="item.key == 'forSale'">
            <span v-if="saleData[item.key] == 1">立即上架（审核成功自动上架）</span>
            <span v-if="saleData[item.key] == 2"
              >自定义 {{ saleData.startTime }}至{{ saleData.endTime }}</span
            >
            <span v-if="saleData[item.key] == 0">暂不上架</span>
          </template>
          <template v-if="item.key != 'forSale' && item.key != 'supplyObject'">
            <span
              >{{ saleData[item.key] || "/" }}
              <!-- 查看商圈判断条件 businessDistrictname -->
              <template v-if="item.key == 'businessDistrictname'">
                <span
                  class="click-box"
                  v-if="saleData[item.key]"
                  @click="viewDq(saleData)"
                >
                  {{ saleData[item.key] ? "点击查看具体区域" : "" }}
                </span>
              </template>
            </span>
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <el-dialog :title="dialogTitle" :visible.sync="isDIS" width="700px">
        <div class="district">
          <!-- <div v-for="item in disData" :key="item">
            <el-tag>{{ item }}</el-tag>
          </div> -->
          <div class="district-box">  
            <div
              v-for="(item, index) in disData"
              :key="index"
              @click="province(index)"
              :class="provinceIndex==index?'pitch-on':''"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-if="disData[provinceIndex]&&disData[provinceIndex].children" class="district-box">
            <div
              v-for="(item, index) in disData[provinceIndex].children"
              :key="index"
              @click="city(index)"
              :class="cityIndex==index?'pitch-on':''"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-if="disData[provinceIndex]&&disData[provinceIndex].children" class="district-box">
            <div
              v-for="(item, index) in disData[provinceIndex].children[cityIndex]
                .children"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="isDIS = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("commonIndex"); //vuex公共库
const Audit = createNamespacedHelpers("Audit");
export default {
  props: {
    saleSetting: {
      type: Array,
      default: () => {
        return [];
      },
    },
    saleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isDIS: false,
      disData: [],
      dialogTitle: "",
      provinceIndex: 0,
      cityIndex: 0,
      // districtIndex: 0,
    };
  },
  computed: {
    ...mapState(["userType"]),
  },
  methods: {
    ...Audit.mapActions(["getgoodsAuditdistrict"]),
    province(val) {
      this.cityIndex = 0;
      this.provinceIndex = val;
    },
    city(val) {
      this.cityIndex = val;
    },
    async viewDq(row) {
      let data = await this.getgoodsAuditdistrict({
        businessDistrictId: row.businessDistrictId,
      });
      if (data.code == 200) {
        this.disData = data.data;
        this.dialogTitle = "【" + row.businessDistrictname + "】的具体区域";
        this.isDIS = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
}
.click-box {
  color: #00bfbf;
  cursor: pointer;
}
.tag-box {
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  div {
    margin: 5px;
  }
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}
.district {
  display: flex;
  > div {
    flex: 1;
  }
}
.district-box{
  height: 300px;
  overflow-y: scroll;
  
  div{
    line-height: 40px;
    padding-left: 20px;
  }
  .pitch-on{
    color: #00bfbf;
  }
}
::v-deep .el-descriptions-item__label {
  width: 80px !important;
  color: #666;
  margin-right: 20px !important;
  font-size: 14px !important;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
</style>
